<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './MrondaViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'descripcion',
        'tmronda_descripcion',
        { name: 'duracion_minutos', label: 'Duración estimada (minutos)' },
        { name: 'ultima_ronda_realizada', filter: this.$options.filters.shortDateTime, label: 'Última ronda realizada' },
        'cliente_nombre',
        'puesto_servicio_descripcion',
        'servicio_descripcion',
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcliente
        item.title = item.cliente_nombre
        item.subtitle = [
          item.descripcion,
          item.puesto_servicio_descripcion,
          item.servicio_descripcion
        ]
        if (item.ultima_ronda_realizada) {
          item.alerts = [{
            value: `Última ronda realizada ${this.$options.filters.shortDateTime(item.ultima_ronda_realizada)}`,
            type: 'info'
          }]
        } else {
          item.alerts = []
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.descripcion
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectMronda(this, this.routeParams.idmronda)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const detailPuntos = this.addDetail(
        'puntos', 'Puntos de control', 'Puntos de control de paso programados', 'puntoControl'
      )
      const [datasetPuntos] = await Data.selectMrondaPuntoCount(this, this.routeParams.idmronda)
      detailPuntos.badge = datasetPuntos.count || 0
    },
    clickDetail (data) {
      if (data.detail.name === 'puntos') {
        this.$appRouter.push({
          name: 'rondas__mronda-punto-list',
          query: {
            idsistema: this.routeParams.idmronda,
          },
        })
      }
    },
  },
}
</script>
