import { APIFilter } from '@/utils/api'

export default {
  async selectMronda (Vue, idmronda) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idmronda', idmronda)
    const resp = await Vue.$api.call('mronda.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectMrondaPuntoCount (Vue, idmronda) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idmronda', idmronda)
    const resp = await Vue.$api.call('mrondaPunto.select', { filter: apiFilter, wrapper: 'count' })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  }
}
